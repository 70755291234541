import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LargeYellowArrow } from './arrows';
import LinkChevron from '../../images/icons/icon-link-chevron.svg';

function CheckIcon() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6 inline mr-2 !my-0"
    >
      <rect y="0.5" width="25" height="25" rx="12.5" fill="#64D09C" />
      <g clipPath="url(#clip0_1240_7543)">
        <path
          d="M5.53613 12.7784L5.53645 18.8866L12.4578 11.9653L19.3788 18.8863L19.3784 12.7781L12.4574 5.85712L5.53613 12.7784Z"
          fill="#1C3837"
        />
      </g>
      <defs>
        <clipPath id="clip0_1240_7543">
          <rect
            width="14.2857"
            height="14.2857"
            fill="white"
            transform="translate(5.35742 5.85712)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const Bucket = ({ Title, Description, URL, Image, Checked }) => (
  <>
    <Link
      to={URL}
      className="sm:hover:bg-brand-blue-1 sm:p-4 transition-all duration-200 ease-in-out group"
    >
      <div>
        <div className="mx-auto sm:w-full">
          <GatsbyImage className="m-auto h-72 upper-left-clip" image={Image} alt={Title} />
        </div>
        <div className="mt-6">
          <h3 className="text-xl leading-6 font-bold text-gray-900">{Title}</h3>
          {Checked ? (
            <div className="flex items-center mt-2">
              <CheckIcon />
              <p className="uppercase font-bold text-sm text-brand-blue-4">We Won!</p>
            </div>
          ) : undefined}
          <p className="mt-2 text-lg text-gray-800">{Description}</p>
        </div>
        <div className="my-5 flex text-brand-blue-4 uppercase text-sm font-bold items-center">
          Read the Full Story
          <img
            className="h-4 w-4 ml-2 group-hover:translate-x-1 transition-all duration-200 ease-in-out"
            src={LinkChevron}
            alt="Link Arrow"
          />
        </div>
      </div>
    </Link>
  </>
);

export default Bucket;
