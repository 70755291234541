import React, { useEffect, useState } from 'react';

const useIsPageScrolled = (timeOut = 0) => {
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  useEffect(() => {
    if (timeOut) setTimeout(() => setIsPageScrolled(true), timeOut);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window != 'undefined' && !isPageScrolled && window?.scrollY > 10)
        setIsPageScrolled(true);
    };
    handleScroll();
    window?.addEventListener('scroll', handleScroll);
    return () => {
      window?.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return isPageScrolled;
};

export default useIsPageScrolled;
