import React, { Fragment, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Bucket from '../components/layout/bucket';
import { SuperButton } from '../components/layout/buttons';
import { SignupForm } from '../components/signup';
import { SmallBlueArrow, SmallGreenArrow } from '../components/layout/arrows';
import BarChart from '../components/pulse/barChart';
import DoughnutWrapper from '../components/pulse/doughnutWrapper';
import Question from '../components/pulse/question';
import QuestionHeader from '../components/pulse/questionHeader';
import PulseHeader from '../components/pulse/pulseHeader';
import SfIllustrationWhite from '../images/core/sf-illustration-white.svg';
import mail from '../images/icons/mail.svg';
import next from '../images/icons/next.svg';
import map from '../images/icons/maps-pin.svg';
import mayor from '../images/icons/mayor.svg';
import HomeListingWrapper from '../components/homeListingWrapper';
import ShowIfPageScrolled from '../components/showIfPageScrolled';
import Carousel from '../components/layout/carousel';
import useIsPageScrolled from '../hooks/useIsPageScrolled';

import { Oct24Housing, Sept24Mayor as PulseSection } from '../components/pulse/snippets';

const Index = ({ data, location }) => {
  const isElection = true;
  const isPageScrolled = useIsPageScrolled();
  const WrongWithHousing = getImage(data.WrongWithHousing.childImageSharp);
  const StrongMayor = getImage(data.StrongMayor.childImageSharp);
  const Prop47 = getImage(data.Prop47.childImageSharp);
  const WholeFoods = getImage(data.WholeFoods.childImageSharp);
  const DumpDean = getImage(data.DumpDean.childImageSharp);
  const ClearConnie = getImage(data.ClearConnie.childImageSharp);
  const CastroTheatre = getImage(data.CastroTheatre.childImageSharp);
  const BoudinRecall = getImage(data.BoudinRecall.childImageSharp);
  const SchoolBoardRecallBus = getImage(data.SchoolBoardRecallBus.childImageSharp);
  const SchoolBoardTrio = getImage(data.SchoolBoardTrio.childImageSharp);
  const Dorsey = getImage(data.Dorsey.childImageSharp);
  const Engardio = getImage(data.Engardio.childImageSharp);
  const Jenkins = getImage(data.Jenkins.childImageSharp);
  const PropD = getImage(data.PropD.childImageSharp);
  const DoloresPark = getImage(data.DoloresPark.childImageSharp);
  const hero = getImage(data.hero.childImageSharp);
  const heroElection = getImage(data.heroElection.childImageSharp);
  const JumpingOnBeach = getImage(data.JumpingOnBeach.childImageSharp);
  const GGB = getImage(data.GGB.childImageSharp);
  const OutdoorDining = getImage(data.OutdoorDining.childImageSharp);
  const SupervisorMap = getImage(data.SupervisorMap.childImageSharp);
  const PlaceForAll = getImage(data.PlaceForAll.childImageSharp);
  const March2024Update = getImage(data.March2024Update.childImageSharp);
  const RcvEducation = getImage(data.RcvEducation.childImageSharp);
  const transitPoll = getImage(data.transitPoll.childImageSharp);
  const buildingTowardNovember = getImage(data.buildingTowardNovember.childImageSharp);

  const LayoutWidth = 'mx-auto px-4 sm:max-w-md sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl';

  const Issue = ({ Title, Description }) => (
    <div className="mb-6 break-inside-avoid-column">
      <h2 className="text-xl font-bold text-gray-800">{Title}</h2>
      <p className="leading-normal text-gray-500">{Description}</p>
    </div>
  );

  const blogList = [
    {
      title: 'Building Toward November',
      description:
        'Replacing our failed leadership is the only way to make SF a liveable place for families.',
      href: '/blog/2024-06-building-toward-november/',
      image: buildingTowardNovember,
    },
    {
      title: 'Our new poll on SF transit and street safety',
      description: 'San Franciscans want to ride Muni, bike, and walk more – safely.',
      href: '/pulse/growsf-pulse-may-2024/',
      image: transitPoll,
    },
    {
      title: 'Ranked choice education key to November',
      description: 'What GrowSF is doing to help SF navigate our choices this fall.',
      href: '/blog/2024-04-ranked-choice-voting-san-francisco/',
      image: RcvEducation,
    },
    /*  {
      title: 'March 2024 Election Impact Report',
      description: 'Details and results from our voter guide and communications programs.',
      href: '/blog/2024-03-march-election-results-release/',
      image: March2024Update,
    },
    {
      title: "What's wrong with housing in San Francisco?",
      description: 'A shortage of homes drives up prices and drives out families.',
      href: '/blog/whats-wrong-with-housing-in-san-francisco/',
      image: WrongWithHousing,
    },
       {
      title: 'The impact of Prop 47 on crime in San Francisco',
      description:
        'In November 2014, California voters approved Proposition 47, The Safe Neighborhoods and Schools Act. It sounded good—the description brought to mind images of preventative crime measures and a more equitable vision for public safety—and big names called for its passage, from Attorney General Kamala Harris to San Francisco District Attorney George Gascon.',
      href: '/blog/prop-47/',
      image: Prop47,
    }, */
  ];
  const currentCampaigns = [
    // {
    //   title: 'GrowSF Pulse Poll: September 2023',
    //   description: 'Read our latest citywide poll.',
    //   href: '/pulse/growsf-pulse-september-2023',
    //   image: Pulse,
    // },
    {
      title: 'Dump Dean Preston',
      description:
        "Dean Preston's policies and actions have shown a disregard for fiscal responsibility, sustainable growth, public safety, and effective governance.",
      href: '/dumpdean',
      image: DumpDean,
    },
    {
      title: 'Clear Out Connie Chan',
      description:
        'Connie Chan has consistently voted against the interests of her constituents and needs to be replaced.',
      href: '/clearoutconnie',
      image: ClearConnie,
    },
    {
      title: 'Find your Supervisor',
      description: 'Use our tool to find and contact your City Supervisor.',
      href: '/sf-district-supervisor-map/',
      image: SupervisorMap,
    },
  ];

  const pastCampaigns = [
    /*     {
      title: 'Recall District Attorney Chesa Boudin',
      description:
        'Residents shouldn’t have to choose between justice reform and safety. We fought for a reform-minded district attorney who helps victims first.',
      href: '/voter-guide/san-francisco-voter-guide-2022-district-attorney-recall-election',
      image: BoudinRecall,
      checked: true,
    }, */
    // {
    //   title: 'Support a new Whole Foods on Geary',
    //   description:
    //     'Let the Planning Commission know you support this project and want to see it approved.',
    //   href: '/issues/whole-foods-geary',
    //   image: WholeFoods,
    // },
    {
      title: 'School Board Recall',
      description:
        'GrowSF was proud to stand with frustrated parents to demand the recall of San Francisco’s incompetent school board.',
      href: '/issues/school-board-recall',
      image: SchoolBoardRecallBus,
      checked: true,
    },
    {
      title: 'Support Joel Engardio for District 4 Supervisor',
      description:
        'Joel will bring a focus on public safety, education, and fiscal responsibility to City Hall.',
      href: '/voter-guide/san-francisco-voter-guide-november-2022-election/#supervisor-district-4',
      image: Engardio,
      checked: true,
    },
    /*     {
      title: 'Support Matt Dorsey for District 6 Supervisor',
      description:
        'Matt Dorsey is a strong voice for public safety, personal responsibility, and for holding drug dealers accountable.',
      href: '/voter-guide/san-francisco-voter-guide-november-2022-election/#supervisor-district-6',
      image: Dorsey,
      checked: true,
    },*/
    {
      title: 'Support Brooke Jenkins for District Attorney',
      description:
        'Jenkins has followed through on her promises to prioritize the prosecution of anti-Asian hate crimes and dealers of deadly drugs. Whether or not you voted for the recall of Chesa Boudin, we think you should vote for Brooke Jenkins.',
      href: '/voter-guide/san-francisco-voter-guide-november-2022-election/#district-attorney',
      image: Jenkins,
      checked: true,
    },
    // {
    //   title: 'Help revitalize the Castro Theatre',
    //   description:
    //     'The Castro Theatre needs your help! The $15 million renovation is at risk from anti-change activists.',
    //   href: '/issues/castro-theatre',
    //   image: CastroTheatre,
    //   checked: true,
    // },
    // {
    //   title: 'Support "A Place for All"',
    //   description:
    //     "The streets aren't a substitute for shelter. We supported legislation to build a shelter for all who need it.",
    //   href: '/issues/end-street-homelessness',
    //   image: PlaceForAll,
    //   checked: true,
    // },
    /*     {
      title: 'Make Outdoor Dining Permanent',
      description: 'We support our small businesses and helped make outdoor dining permanent!',
      href: '/issues/make-shared-spaces-permanent',
      image: OutdoorDining,
      checked: true,
    }, */
    // {
    //   title: 'Support the GrowSF Public Education slate',
    //   description:
    //     'GrowSF stands with Ann Hsu, Lainie Motamedi, and Lisa Weissman-Ward for San Francisco School Board.',
    //   href: '/voter-guide/san-francisco-voter-guide-november-2022-election/#board-of-education',
    //   image: SchoolBoardTrio,
    // },
  ];

  const issuesContent = [
    {
      title: 'Build more housing',
      description:
        'From young families seeking homeownership to seniors, everyone needs an affordable place to live.',
    },
    {
      title: 'Improve public safety',
      description:
        'Increase police staffing, put drug dealers in jail, and provide treatment programs for users.',
    },
    {
      title: 'Reduce homelessness',
      description:
        'Build shelters of all types: navigation centers, permanent supportive housing, and innovative solutions.',
    },
    {
      title: 'Streamline small business regulations',
      description: 'Celebrate new ideas and reward economic innovation to provide jobs.',
    },
    {
      title: 'Improve public schools so they treat parents like partners.',
      description: 'Put the needs of students and families above ideology.',
    },
    {
      title: 'Make our public transit system fast, safe, and reliable.',
      description:
        'From subway tunnels to bus lanes, build infrastructure projects that get transit out of traffic — without letting them become boondoggles.',
    },
    /*     {
      title: 'Reimagine how we use public space.',
      description:
        'Create an oceanside park at the former Great Highway, make Golden Gate Park’s JFK Drive permanently car-free, and expand outdoor dining.',
    }, */
    {
      title: 'Ensure City Hall is free from corruption.',
      description: 'We need to audit every city program and only pay for what works.',
    },
  ];

  return (
    <Layout
      headerOptions={{
        borderless: true,
        blueMobile: true,
        blueDesktop: isElection,
      }}
    >
      <SEO
        title="GrowSF: Highly-researched Voter Guides for Delivering Results in San Francisco"
        description="GrowSF is a community of residents who believe San Francisco can be a world class city for everyone."
      />
      {/* Hero */}
      <section>
        <div className="relative flex flex-col-reverse overflow-hidden -mt-px lg:min-h-[650px] lg:flex-col lg:mt-0">
          <Link
            to="/voter-guide/"
            className={`relative inset-0 lg:absolute ${!isElection && 'pointer-events-none'}`}
          >
            <div className="absolute z-10 -top-0.5 left-0 w-full h-20 bg-gradient-to-b from-[#DAC9BD] to-transparent lg:hidden"></div>
            {isElection && (
              <GatsbyImage
                image={heroElection}
                loading="eager"
                alt="A view of San Francisco from Dolores Park"
                className="hidden h-full z-0 min-h-[250px] -mt-0.5 sm:mt-0 lg:block"
              />
            )}
            <GatsbyImage
              image={hero}
              loading="eager"
              alt="A view of San Francisco from Dolores Park"
              className={`z-0 h-full min-h-[250px] -mt-0.5 sm:mt-0 ${isElection && 'lg:hidden'}`}
            />
          </Link>
          <div
            className={`relative w-full flex flex-1 mx-auto pt-3 px-5 bg-gradient-to-b from-brand-blue-3 to-[#DAC9BD] lg:pt-0 lg:px-0 ${
              isElection ? 'lg:to-[transparent_55%]' : 'lg:bg-none lg:max-w-7xl'
            }`}
          >
            {isElection && <Link to="/voter-guide/" className="absolute z-0 inset-0"></Link>}
            {isElection ? (
              <Link
                to="/voter-guide/"
                className="hero-top z-10 relative text-center bg-white m-auto w-full max-w-max px-6 py-12 lg:p-0 lg:bg-transparent lg:mt-24"
              >
                <h1 className="text-4xl font-black text-brand-blue-4 tracking-tight mb-2 lg:text-white lg:text-7xl">
                  Read the #1 Voter Guide
                </h1>
                <p className="text-lg text-gray-700 leading-relaxed mb-6 lg:text-white lg:text-2xl">
                  GrowSF is trusted by hundreds of thousands of San Franciscans
                </p>
                <div className="block max-w-max mx-auto px-5 py-6 capitalize font-medium rounded-sm text-lg text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 lg:text-xl">
                  Click here to read the GrowSF Voter Guide
                  <img
                    className="hidden ml-2 lg:inline"
                    src={next}
                    height={12}
                    width={12}
                    alt="next"
                  />
                </div>
              </Link>
            ) : (
              <div className="hero-mask z-10 relative mx-auto w-full h-max sm:max-w-lg bg-white px-4 py-6 lg:px-12 lg:-mt-0.5 lg:-ml-0.5 lg:pb-12">
                <div className="px-1 text-center lg:text-left">
                  <h1 className="text-3xl sm:text-4xl lg:text-6xl font-black text-gray-900 tracking-tight">
                    It's time to fix San&nbsp;Francisco
                  </h1>
                  <p className="text-base text-gray-700 leading-relaxed mb-6">
                    Outcomes matter more than ideology, and our officials have failed at the basics:
                    public safety, homelessness, housing, public education, and more. GrowSF pursues
                    common sense solutions to create a San Francisco that works for everyone.
                  </p>
                  <div className="z-10 relative">
                    <h2 className="text-sm font-bold text-brand-blue-5 mb-1.5">
                      Sign up to receive our voter guides and important SF news
                    </h2>
                    <SignupForm formStyle="home" formId="_hero" />
                  </div>
                </div>
                <img
                  className="z-0 absolute bottom-28 right-0 lg:bottom-12"
                  height={114}
                  width={114}
                  src={mail}
                  alt="mail"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      {/* End Hero */}
      <section>
        <div className="px-5 pt-8 sm:pt-16 sm:px-6 lg:px-8">
          <Link
            to="/pulse/growsf-pulse-september-2024-mayor/"
            className="flex flex-col gap-0 max-w-7xl mx-auto p-5 [border-image:linear-gradient(to_top_right,#FEDC6B,#64D09C)_30] border-transparent border-[5px] lg:border-8 xl:gap-12 lg:flex-row lg:p-12"
          >
            <div className="flex flex-col my-auto w-full gap-4 lg:max-w-lg">
              <h1 className="text-center mx-auto text-2xl font-black text-brand-blue-5 tracking-tight capitalize max-w-xl sm:text-4xl lg:text-left lg:text-5xl lg:mx-0">
                Latest Mayor Poll
              </h1>
              <span className="text-center text-sm font-light lg:max-w-lg text-brand-blue-5 mb-1 sm:mb-4 lg:text-left lg:pr-2 xl:pr-16">
                In GrowSF's final Mayoral poll before the November election, we found that Daniel
                Lurie and London Breed are tied for first place. But Mark Farrell and Aaron Peskin
                are close behind!
              </span>
              <button className="hidden justify-center items-center w-max px-5 py-3.5 font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-green-4 via-brand-green-4 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid tracking-widest lg:flex">
                See full results
              </button>
            </div>
            <div className="rounded h-max my-auto -mx-5 lg:mx-0">
              <PulseSection node={null} />
            </div>
            <button className="flex justify-center items-center w-full px-5 py-3.5 font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-green-4 via-brand-green-4 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid tracking-widest sm:w-max lg:hidden">
              See full results
            </button>
          </Link>
        </div>
      </section>
      {/* Start Accomplished projects */}
      {isElection && (
        <section>
          <HomeListingWrapper
            className={''}
            name={'When we vote together, we make SF better'}
            title={'What We Have Accomplished'}
            link={{ text: 'See all', href: '/past-campaigns' }}
            itemsPerRow={3}
            list={pastCampaigns}
            mobileCarousel={true}
            loadCarousel={isPageScrolled}
            breakpoints={{
              // when window width is >= 640px -- sm
              640: {
                slidesPerView: 2.1,
              },
            }}
          ></HomeListingWrapper>
        </section>
      )}
      {/* End Accomplished projects */}
      {/* Begin find your supervisor */}
      {/*       <section>
        <div className="grid md:grid-cols-12 md:gap-4 mx-auto px-5 pt-8 sm:px-6 lg:pt-16 lg:px-8 lg:max-w-7xl">
          <Link
            to="/sf-district-supervisor-map/"
            className='col-span-full flex flex-col p-5 justify-between gap-5 rounded isolate overflow-hidden bg-[url("../images/homepage/callout_map_mobile.png")] lg:bg-[url("../images/homepage/callout_map.png")] bg-cover bg-center lg:flex-row lg:p-12'
          >
            <div className="flex flex-col justify-center items-center gap-1.5 lg:gap-10 lg:flex-row">
              <img
                className="h-20 w-20 my-auto lg:h-24 lg:w-24"
                height={80}
                width={80}
                src={map}
                alt="mail"
              />
              <p className="text-center text-2xl font-black text-brand-blue-5 tracking-tight capitalize max-w-xl sm:text-4xl lg:text-left lg:text-5xl lg:tracking-wide">
                Find Your Supervisor with our District Map
              </p>
            </div>
            <button className="whitespace-nowrap h-max flex justify-center items-center w-max my-auto mx-auto px-5 py-4 uppercase tracking-widest font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid lg:mx-0">
              find your supervisor
            </button>
          </Link>
        </div>
      </section> */}
      {/* End find your supervisor */}
      <div className={`flex flex-col w-full`}>
        {/* Begin 'What we're working toward */}
        <section>
          <div className="grid md:grid-cols-12 md:gap-4 mx-auto px-5 pt-8 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div className="sm:col-span-4">
              <h1 className="text-3xl sm:text-4xl lg:text-6xl font-black text-gray-900 tracking-tight mb-4">
                What we're working towards.
              </h1>
              <SuperButton className="mt-2 sm:mt-10 mb-2" color="blue" size="sm" to="/issues">
                Visit our issues page
              </SuperButton>
              <p className="text-gray-500 mb-8 sm:w-48">
                to learn more and read our in-depth platform
              </p>
            </div>
            <div className="sm:col-span-8 sm:columns-2">
              {issuesContent.map((issue, index) => (
                <Issue key={index} Title={issue.title} Description={issue.description} />
              ))}
            </div>
          </div>
        </section>
        {/* End 'What we're working toward */}
        {/* Begin Check Out Our Latest Pulse Poll */}
        <section>
          <div className="px-5 pt-8 sm:pt-16 sm:px-6 lg:px-8">
            <Link
              to="/pulse/growsf-pulse-october-2024/"
              className="flex flex-col gap-0 max-w-7xl mx-auto p-5 [border-image:linear-gradient(to_top_right,#FEDC6B,#64D09C)_30] border-transparent border-[5px] lg:border-8 xl:gap-12 lg:flex-row lg:p-12"
            >
              <div className="flex flex-col my-auto w-full gap-4 lg:max-w-lg">
                <h1 className="text-center mx-auto text-2xl font-black text-brand-blue-5 tracking-tight capitalize max-w-xl sm:text-4xl lg:text-left lg:text-5xl lg:mx-0">
                  San Franciscans overwhelmingly support building more homes.
                </h1>
                <span className="text-center text-sm font-light lg:max-w-lg text-brand-blue-5 mb-1 sm:mb-4 lg:text-left lg:pr-2 xl:pr-16">
                  San Franciscans overwhelmingly support building more housing and making it easier
                  to modify existing buildings. Yet the city is held hostage by NIMBY elected
                  officials who overwhelmingly block the kinds of reforms people want. Online
                  petitions aren’t enough—the only way to deliver the change our city wants is to
                  win elections.
                </span>
                <button className="hidden justify-center items-center w-max px-5 py-3.5 font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-green-4 via-brand-green-4 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid tracking-widest lg:flex">
                  See full results
                </button>
              </div>
              <div className="rounded h-max my-auto -mx-5 lg:mx-0">
                <ShowIfPageScrolled>
                  <Oct24Housing node={null} />
                </ShowIfPageScrolled>
              </div>
              <button className="flex justify-center items-center w-full px-5 py-3.5 font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-green-4 via-brand-green-4 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid tracking-widest sm:w-max lg:hidden">
                See full results
              </button>
            </Link>
          </div>
        </section>
        {/* End Check Out Our Latest Pulse Poll */}
      </div>
      {/* Begin News from GrowSF Blog */}
      <section>
        <HomeListingWrapper
          className={'pb-7 lg:pb-0'}
          theme={'default'}
          name={'The latest'}
          title={'News from GrowSF'}
          link={{ text: 'See all blog posts', href: '/blog' }}
          list={blogList}
        ></HomeListingWrapper>
      </section>
      {/* End News from GrowSF Blog */}

      {/* Begin Secondary Email Signup */}
      {/*       <section>
        <div className="mx-4 sm:mx-auto bg-brand-blue-1 p-5 sm:p-10 relative overflow-clip mt-10 mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="z-10 relative md:grid md:grid-cols-2 md:gap-24 items-center">
            <div>
              <h2 className="text-lg leading-relaxed md:text-xl md:leading-relaxed font-bold text-gray-900">
                Sign up for GrowSF's weekly roundup of important SF news
              </h2>
            </div>
            <div>
              <p className="mt-3 text-sm font-bold text-gray-700 uppercase">
                Sign up for our weekly newsletter
              </p>
              <SignupForm formStyle="home" formId="_signup" />
            </div>
          </div>
          <img
            className="z-0 scale-75 absolute -bottom-20 -right-20"
            src={SfIllustrationWhite}
            alt="Line art illustration of San Francisco"
          />
        </div>
      </section> */}
      {/* End Secondary Email Signup */}
      {/* Start Homepage Mission */}
      {/* <section>
        <div className="relative mt-6 sm:mt-36 overflow-x-clip">
          <div className="sm:absolute relative inset-0 bottom-20">
            <div className="z-10 absolute inset-0 bg-gradient-to-t from-brand-blue-3 to-transparent" />
            <GatsbyImage
              image={JumpingOnBeach}
              alt="Jumping on beach near Lands End"
              className="z-0 h-full w-full sm:object-cover"
            />
          </div>
          <div className={`mx-auto px-4 -mt-20 -mb-20 sm:px-6 sm:pt-96 md:px-10 lg:max-w-7xl`}>
            <SmallGreenArrow>
              <div className="absolute z-0 -mx-5 bg-brand-blue-3 inset-x-0 inset-y-20 sm:hidden"></div>
              <div className="relative z-[9] lg:grid lg:grid-cols-12 lg:gap-16 bg-brand-blue-5 p-5 sm:p-10 lg:p-20">
                <div className="lg:col-span-5">
                  <h1 className="text-3xl md:text-6xl font-black text-gray-50">Our Mission</h1>
                  <p className="text-2xl text-brand-green-3 leading-relaxed mt-4">
                    GrowSF wants San Francisco to be safe, clean, affordable, and vibrant.
                  </p>
                  <SuperButton className="mt-6 mb-2" color="green" size="sm" to="/mission">
                    Visit our mission page
                  </SuperButton>
                  <p className="text-gray-400 sm:w-56">
                    for a full look at our goals, strategy, and long-term vision.
                  </p>
                </div>
                <div className="lg:col-span-7">
                  <p className="text-gray-50 mb-6 text-lg mt-8 sm:mt-0">
                    GrowSF prioritizes outcomes over ideologies. Our local leaders are not focused
                    on actual progress, and residents feel under-informed about local issues and
                    elections. We want to change that.
                  </p>
                  <p className="text-gray-50 mb-6 text-lg">
                    Our elected officials have failed us on the basics: housing, transit, schools,
                    and public safety. We want common-sense solutions to the problems that regular
                    people face. It’s time to fix San Francisco.
                  </p>
                  <p className="text-gray-50 text-lg">
                    GrowSF supports solutions that embrace San Francisco values. We celebrate art
                    and culture, value compassion, seek sustainability, and practice social justice.
                    GrowSF supports a city government that is efficiently managed, responsive to
                    quality of life issues, and above all follows common sense.
                  </p>
                </div>
              </div>
            </SmallGreenArrow>
          </div>
        </div>
      </section> */}
      {/* End Homepage Mission */}
      {/* Start  projects */}
      <section>
        <HomeListingWrapper
          className={'pb-7 lg:pb-10'}
          theme={'yellow'}
          name={'What we’re doing'}
          title={'Our Current campaigns'}
          link={{ text: 'See all', href: '/campaigns' }}
          itemsPerRow={3}
          list={currentCampaigns}
          mobileCarousel={true}
          loadCarousel={isPageScrolled}
          breakpoints={{
            // when window width is >= 640px -- sm
            640: {
              slidesPerView: 2.1,
            },
          }}
        ></HomeListingWrapper>
      </section>
      {/* End  projects */}
      {/* Start  projects */}
      {!isElection && (
        <section>
          <HomeListingWrapper
            className={'pb-7 lg:pb-10'}
            name={'Our Past Campaigns'}
            title={'What We Have Accomplished'}
            link={{ text: 'See all', href: '/past-campaigns' }}
            itemsPerRow={3}
            list={pastCampaigns}
            mobileCarousel={true}
            loadCarousel={isPageScrolled}
            breakpoints={{
              // when window width is >= 640px -- sm
              640: {
                slidesPerView: 2.1,
              },
            }}
          ></HomeListingWrapper>
        </section>
      )}
      {/* End  projects */}
    </Layout>
  );
};
export default Index;

export const query = graphql`
  query {
    buildingTowardNovember: file(
      relativePath: { eq: "blog/2024-06-building-toward-november/why_growsf_matters.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    transitPoll: file(
      relativePath: { eq: "pulse/growsf-pulse-may-2024/growsf-pulse-may-2024.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    RcvEducation: file(
      relativePath: { eq: "blog/2024-04-ranked-choice-voting-san-francisco/rcv-simulator.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    March2024Update: file(
      relativePath: {
        eq: "blog/2024-03-march-election-results-release/march-2024-election-update.png"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    WrongWithHousing: file(
      relativePath: {
        eq: "blog/whats-wrong-with-housing-in-san-francisco/whats-wrong-with-housing-sf.png"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    StrongMayor: file(
      relativePath: {
        eq: "blog/not-a-strong-mayor-city/san-francisco-is-not-a-strong-mayor-city.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    Prop47: file(relativePath: { eq: "blog/prop-47/prop-47-larceny-theft-san-francisco.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    DumpDean300Donors: file(relativePath: { eq: "listing/blog_opengraph.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    WholeFoods: file(
      relativePath: { eq: "issues/whole-foods-geary/whole-foods-geary-square.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    DumpDean: file(relativePath: { eq: "dumpdean/dump-dean-2.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    ClearConnie: file(relativePath: { eq: "clearoutconnie/clear-connie.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    CastroTheatre: file(
      relativePath: { eq: "issues/castro-theatre/save-the-castro-theatre-square.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    BoudinRecall: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-2022-district-attorney-recall-election/boudin-sq.png"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    SchoolBoardTrio: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/public-education-slate-no-logo.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Dorsey: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/dorsey-endorsement.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Engardio: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/engardio-endorsement.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          quality: 100
        )
      }
    }
    Jenkins: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/jenkins.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          quality: 100
        )
      }
    }
    PropD: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/prop-d.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    SchoolBoardRecallBus: file(
      relativePath: { eq: "issues/school-board-recall/school-bus-recall.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          quality: 100
        )
      }
    }
    DoloresPark: file(relativePath: { eq: "core/dolores-park.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    hero: file(relativePath: { eq: "homepage/hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    heroElection: file(relativePath: { eq: "homepage/hero_election.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    JumpingOnBeach: file(relativePath: { eq: "homepage/jumping-over-water.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    GGB: file(relativePath: { eq: "core/ggb.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    StevensonCTA: file(relativePath: { eq: "issues/build-housing-at-469-stevenson/homes.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    KidsOnBikes: file(relativePath: { eq: "issues/car-free-jfk/kids-on-bikes.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    SupervisorMap: file(relativePath: { eq: "sf-supervisor-map/sf-supervisor-district-map.png" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    OutdoorDining: file(
      relativePath: { eq: "issues/make-shared-spaces-permanent/outdoor-dining.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    PlaceForAll: file(relativePath: { eq: "issues/end-street-homelessness/tinyhomes.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
  }
`;
