import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import arrowDecorator from '../images/icons/icon-arrows-decorator.svg';
import yellowDecorator from '../images/icons/yellow_arrows_decorator.svg';
import { twMerge } from 'tailwind-merge';
import Carousel from './layout/carousel';

const HomeListingWrapper = ({
  className = '',
  theme = 'default',
  name,
  title,
  link,
  itemsPerRow = 3,
  list = [],
  mobileCarousel,
  breakpoints,
  loadCarousel,
}) => {
  const itemsGridClass = {
    1: 'grid-cols-1',
    2: 'grid-cols-1 lg:grid-cols-2',
    3: 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3',
    4: 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
  };
  const themes = {
    default: {
      backgroundClass: '',
      textColorClass: 'text-brand-blue-5',
      linkColorClass: 'text-brand-blue-4',
      borderColorClass: 'border-brand-blue-4',
      ringClass: 'ring-offset-brand-blue-1 hover:ring-blue-300 focus:ring-blue-300',
      arrow: arrowDecorator,
    },
    yellow: {
      backgroundClass: 'bg-brand-yellow-1',
      textColorClass: 'text-brand-yellow-5',
      linkColorClass: 'text-brand-yellow-5',
      borderColorClass: 'border-brand-yellow-5',
      ringClass: 'ring-offset-brand-yellow-1 hover:ring-brand-yellow-3 focus:ring-brand-yellow-3',
      arrow: yellowDecorator,
    },
  };
  const currentTheme = themes[theme];
  return (
    <div className={twMerge(className, currentTheme.backgroundClass)}>
      <div className="grid mx-auto px-5 pt-8 sm:pt-20 lg:max-w-7xl xl:px-8">
        <div className="flex justify-between mb-6">
          <div className="flex flex-col gap-2.5">
            <span className={`text-xs font-bold uppercase ${currentTheme.textColorClass}`}>
              {name}
            </span>
            <img src={currentTheme.arrow} height={12} width={41} alt="Arrow Decorator" />
            <h1
              className={`text-center mx-auto text-2xl font-black tracking-tight capitalize max-w-2xl sm:text-4xl lg:text-left lg:text-5xl lg:mx-0 ${currentTheme.textColorClass}`}
            >
              {title}
            </h1>
          </div>
          <Link
            to={link?.href || '/'}
            className={`hidden whitespace-nowrap mt-auto h-max justify-center items-center w-max px-5 py-4 uppercase font-bold border rounded-sm text-xs transition-all hover:scale-105 bg-gradient-to-r from-transparent via-transparent to-transparent bg-size-200 bg-pos-0 hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:border-transparent hover:bg-pos-100 hover:text-white focus:text-white focus:from-brand-blue-5 focus:via-brand-blue-5 focus:to-brand-blue-5 focus:bg-[length:400%_400%] focus:scale-100 break-inside-avoid tracking-widest lg:mx-0 lg:flex ${currentTheme.linkColorClass}  ${currentTheme.borderColorClass}`}
          >
            {link?.text || 'Go'}
          </Link>
        </div>
        <div className="grid -mx-5">
          {loadCarousel && (
            <Carousel
              className={`!px-5 ${mobileCarousel ? '' : '!hidden'} lg:!hidden`}
              childClassName={'py-5'}
              breakpoints={breakpoints}
              spaceBetween={20}
              slidesPerView={1.2}
            >
              {list?.map((item, index) => (
                <Link
                  key={`ListingCardsTemplate${index}`}
                  to={item.href}
                  className={`flex flex-col justify-self-stretch outline-none border-none justify-start gap-4 ring-offset-8  overflow-hidden isolate hover:ring-2 focus:ring-2 hover:rounded-sm focus:rounded-sm lg:max-w-[347px] ${currentTheme.ringClass}`}
                >
                  <GatsbyImage
                    className="w-full object-contain shadow-lg overflow-hidden isolate rounded-t-md rounded-b-md lg:max-w-[346px] sm:rounded-b-none"
                    image={item.image ? getImage(item.image) : ''}
                    loading="lazy"
                    alt={item.title}
                  />
                  <div className="flex flex-col w-full gap-1 pb-4 px-0 sm:px-4">
                    <h2
                      className={`text-left text-base font-bold capitalize sm:text-base ${currentTheme.linkColorClass}`}
                    >
                      {item.title}
                    </h2>
                    <span className="line-clamp-4 text-sm font-light text-gray-700 sm:line-clamp-3">
                      {item.description}
                    </span>
                  </div>
                </Link>
              ))}
            </Carousel>
          )}
        </div>

        <div
          className={`w-full justify-items-stretch grid-flow-row gap-5 pb-5 items-start relative mx-auto sm:pb-6 sm:gap-12 ${
            itemsGridClass[itemsPerRow]
          } ${mobileCarousel ? 'hidden lg:grid' : 'grid'}`}
        >
          {list?.map((item, index) => (
            <Link
              key={`ListingCardsTemplate${index}`}
              to={item.href}
              className={`flex flex-col justify-self-stretch outline-none border-none justify-start gap-4 ring-offset-8  overflow-hidden isolate hover:ring-2 focus:ring-2 hover:rounded-sm focus:rounded-sm lg:max-w-[347px] ${currentTheme.ringClass}`}
            >
              <GatsbyImage
                className="w-full object-contain shadow-lg overflow-hidden isolate rounded-t-md rounded-b-md lg:max-w-[346px] sm:rounded-b-none"
                image={item.image ? getImage(item.image) : ''}
                loading="lazy"
                alt={item.title}
              />
              <div className="flex flex-col w-full gap-1 pb-4 px-0 sm:px-4">
                <h2
                  className={`text-left text-base font-bold capitalize sm:text-base ${currentTheme.linkColorClass}`}
                >
                  {item.title}
                </h2>
                <span className="line-clamp-4 text-sm font-light text-gray-700 sm:line-clamp-3">
                  {item.description}
                </span>
              </div>
            </Link>
          ))}
        </div>

        <Link
          to={link?.href || '/'}
          className={`bg-transparent whitespace-nowrap mt-auto h-max justify-center items-center w-max px-5 py-4 uppercase font-bold border rounded-sm text-xs transition-all hover:scale-105 bg-gradient-to-r from-transparent via-transparent to-transparent bg-size-200 bg-pos-0 hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:border-transparent hover:bg-pos-100 hover:text-white focus:text-white focus:from-brand-blue-5 focus:via-brand-blue-5 focus:to-brand-blue-5 focus:bg-[length:400%_400%] focus:scale-100 break-inside-avoid tracking-widest lg:mx-0 lg:hidden ${currentTheme.linkColorClass}  ${currentTheme.borderColorClass}`}
        >
          {link?.text || 'Go'}
        </Link>
      </div>
    </div>
  );
};

export default HomeListingWrapper;
